<template>
  <div class="landscapeInfo">
    <div class="landscapeIconContainer">
      <img src="../assets/icons/landscape.svg" alt="landscape_icon" width="16" height="16">
    </div>
    <p>
      Diese App ist für die Anwendung im Querformat vorgesehen. <br><br> Bitte drehen Sie Ihr Gerät oder verbreitern Sie das Browserfenster.
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/styles/base';

.landscapeInfo {
  display: none;
}

@media only screen and (orientation: portrait){
  .landscapeInfo {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    z-index: 50;

    padding: 2rem;

    background-color: $primary;
  }

  .landscapeIconContainer {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;

    padding-top: 2rem;
    padding-bottom: 2rem;

    img {
      width: 50%;
      height: auto;
    }
  }
}

</style>
