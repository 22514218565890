<template>
  <footer class="logos">
    <div class="unis">
      <a href="https://www.fhgr.ch/studium/masterangebot/medien-technik-und-it/digital-communication-and-creative-media-production/?gclid=EAIaIQobChMI-8fm2uzY_QIVh8zVCh0YiQCnEAAYASAAEgK2ZPD_BwE" rel="noopener noreferrer" target="_blank">
        <img class="logo-fhgr" src="../assets/fhgr_rgb_granit.png" alt="Logo FHGR Black" width="7512" height="1182">
      </a>
      <a href="https://www.unifr.ch/dcm/de/studies/master/master-dccmp/" rel="noopener noreferrer" target="_blank">
        <img src="../assets/unifr_black.png" alt="Logo Uni Black" width="1024" height="699">
      </a>
    </div>
    <div class="tool">
      <span class="footerTitle">Joint Master in Digital Communication and Creative Media Production</span><br>
      Ein Tag im Leben eines Studierenden
    </div>
  </footer>
</template>

<script setup>

</script>

<style lang="scss" scoped>
@import '../assets/styles/normalize';
@import '../assets/styles/base';

.logos {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: $logo_distance;
  z-index: 20;


  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  padding-top: $padding_top_bottom_logos;
  padding-bottom: $padding_top_bottom_logos;
  padding-left: 2rem;
  padding-right: 2rem;


  .unis {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    min-width: 0;
    height: 100%;
    width: auto;

    gap: 3rem;

    img {
      height: 3.5rem;
      max-height: 3.5rem;
      min-width: 0;
      min-height: 0;
      width: auto;
      object-fit: contain;
      filter: brightness(0) invert(1);
    }
  }

  .tool {
    font-size: 1.4rem;
    flex-shrink: 8;
    text-align: right;

    .footerTitle {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: $mobile_breakpoint) and (orientation: landscape), only screen and (max-width: $desktop_breakpoint) and (orientation: portrait) {
  .logos {
    display: none;
  }
}

</style>