<template>
  <div class="ctaSection">
    <button class="button" @click="props.openFullscreen ? startJourney() : endJourney()">{{ props.buttonText }}</button>
    <div class="unis">
      <a href="https://www.fhgr.ch/studium/masterangebot/medien-technik-und-it/digital-communication-and-creative-media-production/?gclid=EAIaIQobChMI-8fm2uzY_QIVh8zVCh0YiQCnEAAYASAAEgK2ZPD_BwE" rel="noopener noreferrer" target="_blank">
        <img class="logo-fhgr" src="../assets/fhgr_rgb_granit.png" alt="Logo FHGR Black" width="7512" height="1182">
      </a>
      <a href="https://www.unifr.ch/dcm/de/studies/master/master-dccmp/" rel="noopener noreferrer" target="_blank">
        <img src="../assets/unifr_black.png" alt="Logo Uni Black" width="1024" height="699">
      </a>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from "vue";
import router from "@/router";

const props = defineProps(['path', 'buttonText']);

function startJourney(){
  openFullscreen();
  window.scrollTo(0,1)
  router.push(props.path)
}

function endJourney(){
  router.push(props.path)
}

var elem = document.documentElement;

function openFullscreen() {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) { /!* Safari *!/
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /!* IE11 *!/
    elem.msRequestFullscreen();
  }
}

</script>

<style scoped lang="scss">
@import '../assets/styles/base';

.unis {
  display: none;
}

@media only screen and (max-width: $mobile_breakpoint) and (orientation: landscape), only screen and (max-width: $desktop_breakpoint) and (orientation: portrait) {

  .ctaSection {
    display: flex;
    justify-content: space-between;

  .button {
    font-size: 1.2rem;
  }
}

.unis {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: center;
  min-width: 0;
  height: 100%;

  gap: 1rem;

img {
  max-height: 2rem;
  width: auto;
  min-width: 0;
  min-height: 0;
  object-fit: contain;
  filter: brightness(0) invert(1);
}
}

}

</style>
