<script setup>
  import Footer from '@/components/Footer';
  import LandscapeInfo from "@/components/LandscapeInfo";
  import {computed, ref, watch} from "vue";
  import router from "@/router";

  const patternContainer = ref(null);

  const isVideoOrHomePage = computed(() => {
    if(router.currentRoute.value.params.id || router.currentRoute.value.name === "Home"){
      return true;
    } else {
      return false;
    }
  });

  watch(isVideoOrHomePage, () => {
    if(isVideoOrHomePage.value){
      patternContainer.value.style.setProperty("--display-pattern", "none");
    } else {
      patternContainer.value.style.setProperty("--display-pattern", "block");
    }
  });

</script>
<template>
  <div class="pattern" ref="patternContainer">
  <div class="inner">
    <router-view/>
  </div>

  <Footer />

  <LandscapeInfo />
  </div>
</template>

<style lang="scss">

@import 'assets/styles/normalize';
@import 'assets/styles/base';


#app {
  font-family: $font_family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: $font_color;
  background-color: $primary;
  height: 100vh;
  overflow: hidden;
}

.pattern {
  --display-pattern: "block";
}

.pattern:after {
  position: absolute;
  display: var(--display-pattern);
  content: "";
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('./assets/patterns/bg_pattern.svg');
  background-size: 250px;
  background-position: -30px -30px;
  background-repeat: repeat;
  opacity: 0.05;
  z-index: 0;
  overflow: hidden;
}

.inner {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: calc($logo_distance + $padding_top_bottom_logos + $padding_top_bottom_logos);
  padding: $padding_inner;
  padding-bottom: 0;
  overflow: hidden;
  z-index: 1;
  opacity: 1;
}

@media screen and (max-width: $mobile_breakpoint) and (orientation: landscape), only screen and (max-width: $desktop_breakpoint) and (orientation: portrait) {
  .inner {
    bottom: $padding_inner;
    height: calc(100% - $padding_inner - $padding_inner);
    width: calc(100% - $padding_inner - $padding_inner);
    padding-bottom: $padding_inner;
  }
}


</style>


